<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="text-center agreement-title-top" cols="12">
          개인정보조회 동의 완료
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            
            <v-col class="agreement-total agreement-info" cols="10">
              <v-row>
                <v-col cols="12" class="agreement-total-text text-center">
                  개인정보조회 동의 처리가 완료되었습니다.
                </v-col>
                <v-col cols="12" class="agreement-total-text text-center">
                  감사합니다.
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <agreement-popup
      @agree-checked="checkAgreement($event)"
      v-if="checkAgreementPopup2"
      :popupSetAgree="popupSetAgree"
    />
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
     
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    
  },
  methods: {

  },
  destroyed() {
    if (this.checkPopup !== undefined && this.checkPopup === true) {
      this.SET_POPUP(false);
    } else if (
      this.checkAgreementPopup2 !== undefined &&
      this.checkAgreementPopup2 === true
    ) {
      this.SET_AGREEMENT_POPUP2(false);
    }

    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
  .agreement-title-top {
    font-weight: 500;
    font-size: 34px;
    color: #146f83;
    margin: 50px 0px 50px 0px;
  }

  .agreement-info {
    padding: 35px 30px 35px 30px;
    background-color: #f5f9fa;
    border-radius: 20px;
    margin-bottom: 50px;

    p {
      font-size: 16px;
      word-break: keep-all;
    }

    .space {
      margin: 40px 0px 40px 0px;
    }
  }

  .agreement-total {

    
    .agreement-total-text {
      font-size: 30px;
      font-weight: 500;
    }

    .agreement-sub-text {
      font-size: 24px;
      font-weight: 400;
    }
    
    
  }

  .black-divider {
    background-color: black;
  }

  .all-check {
    
    .check {
      float: right;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50px;
    }
  }
  

  .all-none-check {
    background-color: #e1e1e1;
    img {
      width: 60%;
      margin-top: 13px;
    }
  }

  .all-checked {
    background-color: #146f83;
    img {
      width: 60%;
      margin-top: 13px;
    }
  }

  .sub-check {
    
    .check {
      float: right;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 50px;
    }
  }

  .sub-none-check {
    background-color: #e1e1e1;
    img {
      width: 60%;
      margin-top: 12px;
    }
  }

  .sub-checked {
    background-color: #146f83;
    img {
      width: 60%;
      margin-top: 12px;
    }
  }

  .agreement-content {
    
    margin-top: 50px;;
    font-size: 30px;
    font-weight: 500;

    span {
      font-size: 20px;
      word-break: keep-all;
    }
  }

  .relation-select {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    top: -20px;
  }

  .agreement-phone {
    margin-top: 19px;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    top: -20px;
  }

  .agree-btn {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 25px;
  }

  .agreeBtnActive {
    background-color: #146f83 !important;
  }
  
  @media screen and (max-width: 700px) {
    .agreement-total-text {
      font-size: 22px !important;
    }
  }

  @media screen and (max-width: 530px) {
    .agreement-total-text {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 440px) {
    .agreement-total-text {
      font-size: 15px !important;
    }
  }

  @media screen and (max-width: 380px) {
    .agreement-total-text {
      font-size: 12px !important;
    }
    .agreement-title-top {
      font-size: 30px;
    }
  }

</style>

<style scoped>
  .v-text-field--outlined >>> fieldset {
    border: 0px;
  }
</style>